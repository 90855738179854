body, html {
  margin:0;
  padding:0;
  background:#eeeeee;
}

h1, h2, h3 {
  font-family:'Montserrat', sans-serif;
  font-weight:bold !important;
}

h4, h5, h6, .alert {
  font-family:'Montserrat', sans-serif;
}

p, a {
  font-family:'Khula', sans-serif;
  font-size:1.1rem;
}

ul, ol {
  list-style:none;
}

.badge {
    margin: 0 5px;
}

.hideMe {
  color: rgba(0,0,0,0);
}

.navbar {
  z-index:1000;
}

.heroVid video {
  object-fit: cover;
  z-index:0;
}

.heroOver {
  position:absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 80vh;
  
  text-align:center;
  z-index:1
}

.heroOver h2 {
  margin-top:400px;
  color:#efefef;
}

.heroOver h4 {
  color:#efefef;
}

.modal-content {
  background-color:#050505;
}

.form-control, option {
  background-color:#121212;
  color:#efefef;
  border:#253c97 1px solid;
}

.modal-content h1, .modal-content h2, .modal-content h3, .modal-content h4, .modal-content h5, .modal-content h6, .modal-content p, .modal-content li, .modal-content a, .modal-title, .form-label, th, td {
  color:#efefef;
}

.modal-body li {
  padding: 5px 10px;
  font-size: 16px;
  color:#efefef;
}

.modal-body .card {
  margin:10px 55px !important;
}

.modal-body .col-9 {
  padding-left: 25px !important;
}

.modal-body #boxtype1, .modal-body #boxtype2 {
  padding:10px 20px;
}

.nav-tabs .nav-link.active {
  color:#495057!important;
}

.nav-tabs .nav-item {
  color:#efefef!important;
}

.card-title {
  color:#efefef;
}

.imgbox, .imgbox2 {
  width:80%;
  margin:auto;
}

.modal-body .row img {
  margin: 30px auto;

}

#logos {
  margin:50px auto;
}

.mheadline {
  text-align:center;
  width:80%;
  margin-top:15px;
  margin-bottom:15px;
}

.mheadline h3 {
  font-size:28px;
}

#logoimg {
  transition: transform .2s;
}

#logoimg:hover {
  transform:scale(1.20);
}

.imgrack img {
  max-width: 60px;
  max-height: 60px;
}

.imgrack2 img {
  max-width: 100px;
  max-height: 100px;
}

.imgrack {
  margin-bottom:15px;
}

.imgrack .col-2 {
  text-align:center;
}

.star {
  max-height:60px;
  max-width:45px;
  padding:10px 0;
}

.salebanner .col-3 {
  text-align:center;
}

.salebanner {
  background: rgb(252,176,69);
  background: linear-gradient(90deg, rgba(252,176,69,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}

.salebanner .col-6 {
  text-align:center;
}

.salebanner .col-6 h2 {
  font-size:36px;
  font-family:'Khula', sans-serif;
  color:#fdfd96;
  margin-top:12px;
  text-shadow:#000000 2px 2px;
}

.tinytxt {
  font-size:10px;
  color:#c9c9c9;
}

.map {
  max-width:100%;
}

.merch {
  text-align:center;
  margin-top:50px;
}

.cardrack .col-3 {
  text-align:center;
}

.visa img {
  padding-top:35px;
}

.disc img {
  padding-top:45px;
}

.amex img {
  max-width:100px;
  max-height:100px;
}

.usaf {
  margin-top:25px;
}

.usaf img {
  max-width:100%;
  max-height:100%;
}

.subbtn {
  margin-top:10px;
}

.funnel img {
  border-radius:10px;
}

.modal-header .close{
  background-color:red;
}

.modal-header .close span {
  font-size:38px;
}

.modal-body .imgbox img {
  margin:30px auto;
}

.modal-body .imgbox2 img {
  margin:30px auto;
}

.modal-body .col-4 img {
  margin: 10px 35px;
}

#keybox .col-6 {
  padding: 10px 0;
  text-align:center;
  background:#eeeeee;
  border:#253c97 1px solid;
}

.keytermbox {
  border:#253c97 1px solid;
  margin-bottom:5px;
}

#keybox .col-6 h5 {
  padding-top:25px;
}

#keybox {
  text-align:center;
}

#avgHeader {
  text-align:center;
  color:#23bec8;
}

#convert, #terms, #lowend, #midgrade, #highend {
  border-bottom:#253c97 1px solid;
  padding-top:7px;
  text-align:center;
}

#convert, #terms {
  color:#eeeeee;
  background:#253c97;
}

#lowend {
  color:#253c97;
  background:#eeeeee;
}

#midgrade {
  color:#23bec8;
  background: #dddddd;
}

#highend {
  color:#eeeeee;
  background:#23bec8;
}

#democontent {
  padding:15px 15px 0 15px;
}

#map {
  max-width:100%;
}

#imgbox {
  text-align:center;
}

#aboutimg {
  max-width:50%;
}

#contactbox li {
  border:none;
}

#contactbox ul {
  margin-top:150px;
}

#contactimgbox {
  text-align:center;
}

#officeimg {
  max-width:100%;
}

.navbar {
  background: #131313 !important;
}

.navbar-brand, .nav-link {
  color:#eeeeee !important;
  max-height: 100px;
}

.nav-link:hover {
  color:#23bec8 !important;
}

.dropdown-menu {
  background:#253c97 !important;
  border:#23bec8 1px solid !important;
}

.dropdown-item {
  color:#eeeeee !important;
}

.dropdown-item img {
  margin-right:10px;
}

.dropdown-item:hover {
  background:#23bec8 !important;
}

.carousel-inner {
  height:500px !important;
  overflow:hidden;
  margin-bottom:-10px;
}

.carousel img {
  height:auto;
  width:100%;
}

.carousel-caption {
  bottom:auto;
  top:200px;
}

.jumbotron {
  background:none;
  width:50%;
  margin:auto;
}

.jumbotron h2, .jumbotron h4 {
  text-shadow: #333333 1px 1px;
}

.contentbox {
  padding:15px 50px;
  margin-top:10px;
}

#boxtype1 {
  background:#131313;
  border-top:#23bec8 3px solid;
  border-bottom:#23bec8 3px solid;
  padding:25px 0px;
}

#centertext {
  text-align:center;
  padding-top:30px;
  color:#eeeeee;
}

#centertext h3 {
  color:#95c23d;
}

#rightside {
  text-align:center;
  padding-top:50px;
  color:#eeeeee;
}

#boxtype2 {
  background:#253c97;
  color:#eeeeee;
  text-align:left;
  padding:25px 0px;
}

#boxtype2 h3 {
  font-size:25px;
  margin-bottom:0px;
}

#boxtype3 {
  background:#131313;
  border:#253c97 1px solid;
  text-align:left;
  padding:25px 0px;
  color:#eeeeee;
}

.col-2 img {
  max-height:200px !important;
}

.wideModal {
  width:80%;
  max-width:none !important;
}

.wideModal img {
  max-width:200px !important;
}

#revBox {
  position:absolute;
  top:100px;
  right:645px;
  background:#253c97;
  width:32%;
  padding:5px;
  border-radius:5px;
  border:#ffffff 1px solid;
  box-shadow:#222222 1px 1px 5px 5px;
}

#revNum {
  float:left;
  background:#30c54e;
  color:#333333;
  width:38%;
  text-align:center;
  height:60px !important;
  border:#ffffff 1px solid;
  border-radius:5px;
}

#revNum h4 {
  font-size:34px;
  margin-top:9px;
}

#revText {
  float:right;
  text-align:left;
  width:59%;
  color:#eeeeee;
  height:60px;
}

#revText h4 {
  font-size:26px;
}

.tiny {
  font-size:8px;
  color:#999999;
}

.form-label {
  margin-top:10px;
  margin-bottom:2px;
}

#package, #pricing, #contactinfo {
  border:#253c97 1px solid;
  border-radius:5px;
  padding:10px;
}

#inforow {
  width:100%;
  text-align:center;
  background:#253c97;
  color:#eeeeee;
  margin:auto;
}

#inforow h5 {
  margin:5px 18px;
}

.col-6 li, .col-12 li {
  border:#23bec8 1px solid;
  font-size:14px;
}

#infocenter .col-6, #infocenter .col-12 {
  margin-top:5px;
  margin-left:-12px;
}

#testimg {
  float:left;
  margin-right:10px;
}

#testbox {
  border:#23bec8 1px solid;
  padding:10px;
  margin-bottom: 5px;
  width: 100%;
}

#testbox h4 {
  color:#253c97;
}

#generated {
  margin:25px auto;
  background:#121212;
  color:#eeeeee;
  padding:10px 25px !important;
  border-radius:10px;
  border:#23bec8 1px solid;
}

#generated .col-6 {
  border:#23bec8 1px solid;
  border-radius:5px;
  padding:10px 10px 0px 10px;
  text-align:center;
  background:#253c97;
  height:85px;
}

#genhead {
  border-bottom: #121212 1px solid;
}

#genhead h6 {
  font-size:24px !important;
  text-transform: uppercase;
}

#gendata h5 {
  font-size: 26px;
}

.container .row .col-4 {
  text-align:center;
}

.container .row .col-4 img {
  margin:15px !important;
}

#footer {
  background:#121212;
  margin-top:10px;
  margin-bottom:0;
  padding:15px;
}

#copyinfo {
  color:#aaaaaa;
}

#gentext li {
  border:none;
  list-style:disc;
}

#clientimgs .col-12 img {
  max-width:100%;
}

#clientimgs .col-12 {
  text-align:center;
}

.modal-body #clientimgs .row .col-6, .modal-body #clientimgs .row .col-8, .modal-body #clientimgs .row .col-2 {
  text-align:center !important;
  margin-top:40px;
}

#clientimgs .row {
  border:#121212 1px solid;
  border-radius: 5px;
  margin-bottom:10px;
}

#clientimgs .row .btn {
  margin-top: 70px;
}

#quote {
  border:#23bec8 1px solid;
  border-radius:5px;
  background:#121212;
  color:#eeeeee;
  padding:5px;
  margin-bottom:15px;
  text-align:center;
}

.tab-content {
  text-align:center;
}

.nav-tabs .nav-item {
  width:150px;
  padding-left:10px;
  border:none;
}

.nav-tabs .nav-item:hover {
  background:#253c97 !important;
  color:#eeeeee !important;
}

label {
  color: #efefef;
}

#last .accordion .card {
  border-bottom:#efefef 1px solid;
  border-radius:5px;
}

#last .accordion .card-header {
  background-color:#232323;
}

#last .accordion .card-body {
  background-color:#232323;
}

.testbox p {
  font-size:16px;
}

#custkeybox {
  margin-bottom:5px;
  text-align:center;
}

.custkeytermbox {
  margin:auto;
  text-align:center;
  border:#23bec8 1px solid;
  border-radius:10px;
}

#rowheader {
  text-align:center !important;
  margin:auto;
  height:40px;
}

#rowheader h4 {
  text-align:center;
  margin:auto;
}

.custkeytermbox .row {
  height:150px;
  text-align:center;
}

.vidlink {
  text-align:center;
  margin:auto;
}

.vidlink img:hover {
  background:#333333;
  border-radius:5px;
}

.vidlink img {
  max-height:50px;
  max-width:50px;
  padding:3px;
  margin:0 !important;
}

.custkeytermbox .row .col-4 img {
  max-height:50px;
  max-width:50px;
  margin: auto;
  padding:3px;
}

.custkeytermbox .row .col-4 img:hover{
  background:#333333;
  border-radius:5px;
}

.custkeytermbox .row .col-6 {
  border:#000000 1px solid;
}

#leadbox {
  border:#333333 1px solid;
  border-radius:5px;
  margin-bottom:10px;
  height:75px;
}

#leadbox h5 {
  display:inline;
  margin-right:10px;
  line-height:15px;
  font-size:13px;
}

.modal-body .card {
  margin:10px !important;
}

.list-group-item {
  background:#eeeeee;
  height:40px;
  padding:5px;
}

.company {
  height:60px !important;
}

.Hot {
  background:rgba(255, 0, 0, 0.4);
}

.Warm {
  background:rgba(255, 153, 0, 0.5);
}

.Cold {
  background:rgba(0, 102, 255, 0.4);
}

.Dead {
  background:#333333;
}

.Dead .card-body {
   color:#eeeeee;
}

.New {
 background:rgba(0, 153, 0, 0.4);
}

.leadhead {
  height: 30px !important;
}

.leadhead h5 {
  font-weight:bold;
}

.listHeader {
  font-weight:bold;
  font-size:20px !important;
  border-bottom:#999999 1px solid;
  width:50%;
}

.Generation {
  background:rgba(51, 204, 51, 0.6);
}

.Selection {
  background:rgba(0, 255, 255,0.4);
}

.URL {
  background:rgba(255, 51, 204,0.4);
}

.Build {
  background:rgba(0, 102, 255,0.4);
}

.Maintenance {
  background:rgba(255, 255, 0,0.4);
}

.Expired, .Expired .list-group-item {
  background: rgba(0,0,0,0.7);
  color:#eeeeee;
}

#admin-tabs-tabpane-customers .list-group-item, #admin-tabs-tabpane-customers .list-group-item a {
  font-size:14px;
}

#customerTabs-tabpane-termselect select {
  width:50%;
  margin:auto;
}

#areas li {
  display:inline;
}

#demobox {
  text-align:center;
}

#btnOne, #btnTwo, #btnThree {
  margin-bottom:25px;
}

#btnThree {
  margin-top:25px;
}

#pkgs {
  margin-top:25px;
}

#adlert {
  font-size:24px;
  font-weight:bold;
}

.pkgimg {
  max-width:15%;
}

.pkgnum {
  font-size:24px !important;
  font-weight:bold;
  font-family:'Muli', sans-serif;
}

.pkgtbl td, .pkgtbl th {
  text-align:center;
}

.pkgtbl td {
  font-size:16px;
}

.firstcol {
  width:400px;
  font-style:italic;
}

#tabletitle {
  text-align:center;
  margin-top:25px;
}

.pkgmobile {
  display:none;
}


/* SMALLER SCREENS AND MOBILE FIXES */

@media only screen and (max-width: 1025px) {
  .navbar-toggler {
    background:#eeeeee;
  }

  .carousel {
    display:none;
  }

  #phone img {
    max-width: 125px;
    max-height: 100px;
    margin-top:50px;
    /*! margin-top:10px; */
    /*! margin-left:5px; */
  }

  #contentimg {
    max-width:125px!important;;
    max-height:125px!important;;
  }

  #boxtype1 img {
    max-width:125px;
    max-height:100px;
    margin-top:50px;
  }

  .modal-body #clientimgs .row .col-6, .modal-body #clientimgs .row .col-md-3 {
    margin-top:10px;
  }

  .modal-body #clientimgs .row .col-6 h5 {
    font-size:18px;
    margin-top:22px;
  }

  .modal-body #clientimgs .row .col-md-3 .btn {
    margin-top:10px;
  }

  #testbox .col-lg-4 img {
    display:none;
  }

  #layer3 .modal-body .container .row .col-4 img {
    max-width:75px;
  }

  #genhead h6 {
    font-size:14px !important;
  }

  #gendata h5 {
    font-size:16px !important;
    margin-top:7px;
  }

  .imgrack {
    text-align:center;
  }

  .imgrack img {
    max-width:60px;
    max-height:60px;
  }

  .col-sm-6 img {
    max-width:50%;
    max-height:100%;
  }

  .usaf img {
    max-width:100%;
    max-height:100%;
  }

  #quote {
    text-align:center;
    font-size: 16px;
  }

  #clientimgs {
    text-align:center;
  }

  #clientimgs .col-6 {
    margin:-25px auto 0px;
  }

  #clientimgs .col-6 h5 {
    padding-top:0px;
    margin-top:0px !important;
  }

  .botbtn {
    margin-bottom:20px;
  }

  .container .row .col-4 img {
    max-width:60px;
    max-height:60px;
  }

  #convert h6, #terms h6, #lowend h6, #midgrade h6, #highend h6 {
    font-size:12px !important;
  }

  .tab-pane img {
    max-width:325px;
    margin:auto;
  }

  #rowheader h4 {
    font-size:20px;
  }

  #contactus {
    text-align:center;
  }

  #contactimgbox {
    display:none;
  }

  #contactbox, #contactbox ul {
    width:100%;
    max-width:100%;
    flex:0 0 100%;
    margin-top:0px;
  }
}

@media only screen and (max-width: 410px) {
  .navbar-brand {
    max-width:30%;
  }

  .navbar-brand img {
    max-width:250px;
    max-height:80px!important;
    height:100px!important;
  }

  .heroOver h2 {
    font-size:20px;
  }

  .heroOver h4 {
    font-size:16px;
  }

  #phone {
    text-align:center;
  }
  #phone img {
    max-width: 100%;
    max-height: 175px;
    margin-top:0;
  }

  #boxtype1 .col-sm-12 {
    text-align:center;
  }

  #boxtype3 h1 {
    font-size:28px;
    margin-top:25px;
  }

  #contentimg {
    max-width:125px!important;
    max-height:125px!important;
  }

  .modal-title {
    font-size:15px!important;
  }

  .mheadline h3 {
    font-size:20px;
  }

  .imgrack .col-2 img {
    max-width:40px;
  }

  .cardrack .col-sm-6 {
    text-align:center;
  }

  .pkgtbl {
    display:none;
  }

  .pkgmobile {
    display:block;
  }

  .pkgmobile ul {
    list-style:disc !important;
  }

  .pkgmobile li {
    border: none;
    font-size:16px;
  }

  .pkghead {
    font-weight:bold;
    font-style:italic;
    font-size:18px;
    list-style:none;
  }
}
